const l = {
  "base.and": "og",
  "base.toc": "Vilkår & Betingelser",
  "base.privacyPolicy": "Privatlivsspolitik",
  "base.createAccount": "Opret konto",
  "base.signin": "Log ind",
  "base.signup": "Opret konto",
  "base.logOut": "Log ud",
  "authentication.description": "Log ind eller opret en konto",
  "invitation.wrongEmailTitle": "Allerede logget ind",
  "invitation.wrongEmailText":
    "Det ser ud til, at du allerede er logget ind på en anden konto. Log venligst ud og prøv igen.",
  "invitation.invalidLinkTitle": "Ugyldig invitation",
  "invitation.invalidLinkText": "Invitationslinket er ugyldigt",
  "invitation.completeSignup": "Venligst fuldfør tilmeldingen til ",
  "invitation.completeSignupDescription":
    "En bekræftelses-e-mail er sendt til din adresse. Hvis du ikke ser den i din indbakke, skal du tjekke din spam-mappe.",
  "invitation.completeSignupButton": "Fuldfør tilmelding",
  "invitation.mustSignupTitle": "Du er blevet inviteret til ",
  "invitation.signInToAccept":
    "Venligst log ind for at acceptere invitationen. I invitations-e-mailen, du modtog, finder du en midlertidig adgangskode.",
  "invitation.acceptTermsOnSignup":
    "Ved at logge ind accepterer du automatisk vores",
  "invitation.accept": "Log ind for at acceptere invitationen",
  "invitation.failed":
    "Fejl ved accept af invitation. Venligst prøv at logge ud og ind - eller bed administratoren om at invitere dig igen.",
  "invitation.accepted": "Invitation accepteret",
  "invitation.emailNotVerified":
    "E-mail ikke verificeret. Venligst verificer din e-mail og prøv igen.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeriet",
  "country.france": "Frankrig",
  "country.spain": "Spanien",
  "country.china": "Kina",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brasilien",
  "country.italy": "Italien",
  "country.denmark": "Danmark",
  "head.title": "Talkio AI | Lær sprog med AI",
  "landing.title1": "Lær ",
  "landing.title2": "Sprog ",
  "landing.title3": "med Kunstig Intelligens",
  "landing.subtitle1":
    "Den ultimative sproglæringsapp, der bruger kunstig intelligens til at hjælpe dig med at forbedre dine mundtlige sprogfærdigheder.",
  "landing.button.freeTrial": "7 dage gratis",
  "landing.creditCardNotice": "Kreditkort påkrævet - opsig når som helst",
  "landing.text.voiceControlTeam": "Fra teamet bag Voice Control for ChatGPT",
  "landing.text.trustedBy": "Mere end 500.000 brugere",
  "landing.card.title.advancedAI": "Baseret på kunstig intelligens",
  "landing.card.text.advancedAI":
    "Talkio er baseret på avanceret kunstig intelligens og lader dig træne dit ordforråd og udtale gennem samtaler.",
  "landing.card.title.multipleLanguages": "Stort udvalg af sprog",
  "landing.card.text.multipleLanguages":
    "Vi tilbyder et stort udvalg af sprog, herunder engelsk, kinesisk, fransk og mange andre.",
  "landing.card.title.premiumVoice": "Realistiske stemmer",
  "landing.card.text.premiumVoice":
    "Talkio's tutorer har stemmer der lyder realistiske og understøtter mange forskellige dialekter på tværs af sprog. Med vores avancerede sprogteknologi kan du fordybe dig i autentiske samtaler og opnå færdigheder i de dialekter, der betyder mest for dig.",
  "landing.sectionTitle.meetTutors": "Mød AI-tutorene",
  "landing.sectionText.moreThanTutors":
    "Talkio har mere end 400 tutorer med unikke kunstige personligheder, der er klar til at hjælpe dig med din læring.",
  "landing.text.andManyMore": "Og mange flere",
  "landing.text.learnWithAITutors": "Lær med Kunstig Intelligens",
  "landing.text.wonderedAboutTutor":
    "Har du nogensinde tænkt over, hvordan det ville være at have en personlig sprogunderviser tilgængelig når som helst og hvor som helst? Hos Talkio gør vi denne drøm til virkelighed. Vores AI-tutorer er de perfekte ledsagere til at forbedre dine mundtlige færdigheder. Drevet af avanceret AI-teknologi efterligner de menneskelig interaktion og samtale, og tilbyder en spændende og effektiv sprogindlæring.",
  "landing.text.rememberLanguageLearning":
    "Husk, sprogindlæring er mere end bare grammatik og ordforråd; det handler om at kunne udtrykke sig selv med selvtillid. Med Talkio's intelligente tutorer kan du snakke dig til bedre sprogfærdigheder!",
  "landing.sectionTitle.featureSpotlight": "Funktioner",
  "landing.sectionText.featureSpotlight":
    "Her er et overblik over Talkio's funktioner",
  "landing.text.safeEnvironment": "Tal for dig selv",
  "landing.text.supportiveEnvironment":
    "Når det kommer til at lære sprog, så er det faktisk rart at kunne 'tale for sig selv' - altså øve sig uden at andre kan høre det. Med Talkio kan du have meningsfulde og spændende samtaler med en kunstig intelligent tutor. Det føles som at tale med en rigtig person, men uden frygten for at begå fejl.",
  "landing.sectionTitle.insights": "Anmeldelser",
  "landing.sectionText.realStories": "Ægte historier fra brugere af Talkio",
  "landing.sectionTitle.faq": "Ofte stillede spørgsmål",
  "landing.sectionText.faq": "Svar på hyppige spørgsmål om Talkio",
  "landing.sectionTitle.pricing": "Priser",
  "landing.sectionText.unlockPotential":
    "Tag dine sprogfærdigheder mod nye højder med Talkio. Tegn et abonnement og oplev en verden af muligheder.",
  "landing.sectionText.offersForPartners":
    "Vi tilbyder også abonnementer for skoler og firmaer",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurel venstre",
  "landing.imageAlt.laurelRight": "Laurel højre",
  "landing.imageAlt.speakingWithAI": "Kvinde der taler med AI",
  "testimonial.text1":
    "Jeg har personligt brugt Talkio til at forbedre mit spanske, og lad mig sige dig, det er en fantastisk mulighed.",
  "testimonial.text2":
    "Talkio AI er et meget imponerende værktøj og bør inkluderes i enhver sprogelevs værktøjskasse.",
  "testimonial.text3":
    "Dette værktøj Talkio AI blæser mig bagover ift. sprogindlæring.",
  "testimonial.text4": "Talkio AI - det er et fantastisk produkt.",

  "landing.text.voiceConversations": "Samtaler",
  "landing.text.voiceConversationsDescription":
    "Hav spændende samtaler med vores AI-tutorer",
  "landing.text.pronunciation": "Udtale",
  "landing.text.pronunciationDescription":
    "Øv din udtale med feedback ord-for-ord",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Få øjeblikkelig feedback på dine sprogfærdigheder, og tips til hvordan du forbedrer dem",
  "landing.text.multipleLanguages": "Mange sprog og dialekter",
  "landing.text.multipleLanguagesDescription":
    "Vælg mellem 134 sprog og dialekter",
  "landing.text.progress": "Fremskridt",
  "landing.text.progressDescription":
    "Følg din udvikling og få ugentlige rapporter om dine fremskridt",
  "landing.text.wordbook": "Ordbog",
  "landing.text.wordbookDescription": "Gem og genbesøg de ord du lærer",
  "landing.text.streaks": "Streaks",
  "landing.text.streaksDescription":
    "Opbyg gode vaner og hold en stime af daglige øvelser",
  "landing.text.crosstalk": "Crosstalk",
  "landing.text.crosstalkDescription":
    "Snak med tutoren på dit modersmål og det sprog du lærer",
  "landing.text.translations": "Oversættelser",
  "landing.text.translationsDescription":
    "Øjeblikkelige oversættelser for at støtte dig i dine samtaler",
  "landing.text.featureSpotlight": "Features",
  "landing.text.featureSpotlightDescription":
    "Vi har pakket Talkio med funktioner, der hjælper dig med at forbedre dine sprogfærdigheder",

  "feature.lifeLikeConversations": "Kunstige samtaler der føles ægte",
  "feature.pronunciationTool": "Værktøj til udtaleforbedring",
  "feature.detailedFeedback": "Detaljeret feedback på dine sprogkundskaber",
  "feature.interactiveWordbook": "Interaktiv ordbog",
  "feature.supportsLanguages": "Understøtter mere end 40 sprog",
  "feature.practiceSpeaking.title": "Lær at tale flydende",
  "feature.practiceSpeaking.bullet1": "Livagtige samtaler med en AI-tutor",
  "feature.practiceSpeaking.bullet2": "Tal og bliv hørt",
  "feature.practiceSpeaking.bullet3": "Forslag til samtaleemner",
  "feature.practiceSpeaking.bullet4": "Prøv igen og igen",
  "feature.worldOfLanguages.title": "En verden af sprog",
  "feature.worldOfLanguages.bullet1": "Understøtter mere end 40 sprog",
  "feature.worldOfLanguages.bullet2": "Løbende oversættelser",
  "feature.worldOfLanguages.bullet3": "Flersproget talegenkendelse",
  "feature.worldOfLanguages.bullet4": "Hjælp til forståelse af skriftsprog",
  "feature.actionableFeedback.title": "Feedback du kan bruge",
  "feature.actionableFeedback.bullet1": "Sætningsstruktur",
  "feature.actionableFeedback.bullet2": "Forbedringsstrategier",
  "feature.actionableFeedback.bullet3": "AI-forslag",
  "feature.actionableFeedback.bullet4": "Udtalescore",
  "feature.pronunciationPractice.title": "Øv din udtale",
  "feature.pronunciationPractice.bullet1": "Bedømmelse ord-for-ord",
  "feature.pronunciationPractice.bullet2": "Vurdering af udtale",
  "feature.pronunciationPractice.bullet3": "Forslag til udtaleforbedring",
  "feature.pronunciationPractice.bullet4": "Prøv igen og igen",
  "feature.interactiveWordbook.title": "Interaktiv ordbog",
  "feature.interactiveWordbook.bullet1": "Hold styr på de ord du lærer",
  "feature.interactiveWordbook.bullet2": "Repeteres gennem samtaler",
  "feature.interactiveWordbook.bullet3": "Oversættelse og oplæsning",
  "feature.interactiveWordbook.bullet4": "Sætningseksempler",
  "feature.button.startTrial": "Start din gratis prøveperiode",
  "faq.question1": "Er der en gratis udgave?",
  "faq.answer1":
    "Nej, alle abonnementer er betalte. Dog har vi en gratis prøveperiode på 7 dage, som kan afbestilles når som helst. Ingen forpligtelser.",
  "faq.question2": "Hvem står bag Talkio?",
  "faq.answer2.part1": "Talkio er udviklet i Danmark af",
  "faq.answer2.part2":
    ". Vi overholder selvføgelig gældende regler, inklusiv GDPR, for at levere en sikker og tryg oplevelse for alle brugere.",
  "faq.question3": "Er Talkio egnet for begyndere?",
  "faq.answer3":
    "Talkio egner sig bedst til personer med en grundlæggende viden om det pågælende sprog, som ønsker at forbedre deres mundtlige sprogfærdigheder. For begyndere har vi dog udviklet en gratis introduktionsguide. Denne guide dækker de grundlæggende aspekter af dit valgte sprog, så du kan komme godt igang med Talkio. Tjek guiden og start din rejse mod bedre sprogkundskaber.",
  "faq.button.gettingStartedGuide": "Prøv GRATIS introduktionsguide",
  "faq.question4": "Er betaling sikker?",
  "faq.answer4":
    "Ja, vi bruger Stripe til at håndtere betalinger. Vi gemmer ikke dine kreditkortoplysninger.",
  "faq.question5": "Hvor opbevares mine data?",
  "faq.answer5":
    "Dine data opbevares på servere i EU: Microsoft (stemmer og data), Auth0 (login), Stripe (betaling). Derudover bruger vi tjenester fra OpenAI, Anthropic og Groq til at drive AI'en. Disse data behandles i USA.",
  "faq.question6": "Kan jeg få en refundering?",
  "faq.answer6":
    "Den gratis prøveperiode kan annulleres til enhver tid; men hvis du glemmer at annullere prøveperioden, kan vi refundere dit køb inden for 14 dage, forudsat at du ikke har brugt tjenesten efter prøveperioden sluttede. Kontakt os venligst på support@talkio.ai",
  "faq.question7": "Hvilke betalingskort kan jeg bruge?",
  "faq.answer7":
    "Vi accepterer alle større kreditkortudbydere, eksempelvis VisaDankort, MasterCard og Paypal.",
  "faq.question8": "Har Talkio en mobilapp?",
  "faq.answer8":
    "Talkio er en 'Progressiv Web App', hvilket betyder, at du kan installere den fra browseren uden brug af App Store eller Google Play. På Android, finder du en installationsknap i din browser efter tilmelding. På iOS åbner du Talkio i Safari, trykker på delingsikonet og vælger 'Tilføj til Hjemmeskærm'.",
  "faq.question9": "Hvilke browsere kan jeg bruge?",
  "faq.answer9":
    "Talkio fungerer i alle moderne browsere, herunder Chrome, Firefox, Safari og Edge. Den fungerer også i mobilbrowsere på iPhone og Android.",
  "faq.question10": "Hvilke AI-modeller bruger I?",
  "faq.answer10":
    "Talkio bruger en blanding af forskellige AI-modeller fra OpenAI, Anthropic og Meta. Hver model har en specifik opgave, de er gode til, og sammen skaber de en kraftfuld AI-tutor.",
  "faq.question11":
    "Hvordan adskiller Talkio sig fra Voice Control for ChatGPT?",
  "faq.answer11":
    "Talkio tilbyder en sammenhængende oplevelse med AI tilpasset sproglæring. Den tilbyder også flere funktioner, herunder vurdering af udtale, bedre stemmeteknologi, support for flersproget tale, kuraterede træningsmaterialer, integreret feedback og oversættelser.",
  "faq.question12": "Hvordan kan jeg kontakte jer?",
  "faq.answer12":
    "Du er meget velkommen til at skrive en e-mail til hello@talkio.ai",
  "layout.serviceBar.text": "Udviklet i Danmark. Vi respekterer dit privatliv.",
  "layout.serviceBar.community":
    "Tilslut dig et verdensomspændende fællesskab af sprogstuderende",
  "layout.nav.pricing": "Priser",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Sprog",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "Talkio for Skoler",
  "layout.nav.teams": "Talkio for Firmaer",
  "layout.nav.affiliateProgram": "Affiliate-program",
  "layout.nav.signIn": "Log ind",
  "layout.nav.goToApp": "Gå til app",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige sprogfærdigheder.",
  "layout.cta.button": "Prøv Talkio AI",
  "layout.footer.pages": "Sider",
  "layout.footer.learningHub": "Læringshub",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Om",
  "layout.footer.affiliateProgram": "Affiliate-program",
  "layout.footer.schools": "Talkio for Skoler",
  "layout.footer.teams": "Talkio for Firmaer",
  "layout.footer.languageGuide": "Gratis introduktionsguide for begyndere",
  "layout.footer.termsConditions": "Vilkår og Betingelser",
  "layout.footer.privacyPolicy": "Fortrolighedspolitik",
  "layout.footer.logout": "Log ud",
  "layout.footer.login": "Log ind",
  "layout.footer.blogPosts": "Blogindlæg",
  "layout.footer.blogPost1": "Ny funktion på Talkio AI: Interaktiv sprogguide",
  "layout.footer.blogPost2": "Talkio integrerer tre nye tilpassede GPT'er",
  "layout.footer.blogPost3":
    "Hæv dine IELTS mundtlige færdigheder med Talkio AI: En trin-for-trin guide",
  "layout.footer.blogPost4": "3 effektive metoder til at øve mundtlig engelsk",
  "layout.footer.blogPost5": "Bestå dine engelske færdighedstest",
  "layout.footer.blogPost6":
    "Mestring af TOEFL med Talkio AI: Din essentielle guide",
  "layout.footer.contact": "Kontakt",
  "layout.footer.rights": "Alle rettigheder forbeholdes.",
  "layout.footer.illustrationCredits":
    "Illustrationer på forsiden af vectorjuice",

  "pricing.description.perMonthBilledOnce": "pr. måned - opkrævet én gang",
  "pricing.description.perMonthBilledYearly": "pr. måned - opkrævet årligt",
  "pricing.description.perMonthBilledQuarterly":
    "pr. måned - opkrævet kvartalsvis",
  "pricing.description.perMonth": "pr. måned",
  "pricing.button.buyNow": "Køb nu med 7-dages pengene-tilbage garanti",
  "pricing.button.startFreeTrial": "Start din gratis prøveperiode",
  "pricing.features.refund":
    "7-dages fuld refundering - kan annulleres når som helst",
  "pricing.features.freeTrial":
    "7-dages gratis prøveperiode - kan annulleres når som helst",
  "pricing.features.supportsLanguages": "Understøtter 40+ sprog",
  "pricing.features.voiceConversations": "Kunstige samtaler der føles ægte",
  "pricing.features.instantFeedback": "Øjeblikkelig feedback",
  "pricing.features.pronunciationPractice": "Værktøj til udtaleforbedring",
  "pricing.features.interactiveWordbook": "Interaktiv ordbog",
  "pricing.features.speechRecognition": "Flersproget talegenkendelse",
};

export default l;
