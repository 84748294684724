import { useEffect } from "react";
import { ConversionTrackingStatus } from "../pages/api/user/track-conversion";
import { fetchApi } from "../services/fetch-api";
import { logger } from "../utils/logger";
import { trackVwo } from "../utils/track-vwo";
import { useLocalStorage } from "./use-local-storage";
import { useUser } from "./use-user";

export interface ConversionTrackerStorage {
  params?: string;
  referrer?: string;
}

export const conversionTrackerStorageKey = "conversion-tracker";

export function useConversionTracker() {
  const { user, updateConversionTracking, userIsLoading } = useUser(false);
  const { storageData, setStorageData, isLoadingStorage } =
    useLocalStorage<ConversionTrackerStorage>(conversionTrackerStorageKey);

  useEffect(() => {
    if (userIsLoading || isLoadingStorage) {
      return;
    }

    // Update conversion tracking if the user is logged in and the conversion data is not set
    if (user && !user.conversionParams && !user.conversionReferrer) {
      const params = storageData?.params || "Unknown";
      const referrer = storageData?.referrer || "Unknown";

      updateConversionTracking(params, referrer);
    } else if (!storageData) {
      logger.info("No conversion data found, setting it now");

      setStorageData({
        params: new URLSearchParams(window.location.search).toString(),
        referrer: document.referrer,
      });
    }
  }, [
    user,
    storageData,
    userIsLoading,
    isLoadingStorage,
    setStorageData,
    updateConversionTracking,
  ]);

  useEffect(() => {
    const noOrg = user && user.organizations && user.organizations.length === 0;

    if (user && noOrg && !user?.conversionTracked) {
      fetchApi<ConversionTrackingStatus>("/user/track-conversion").then(
        (res) => {
          if (res.frontendShouldTrack) {
            logger.info("Track conversion");
            trackVwo("paidconversion", user.productId, user.subscriptionPrice);
          }
        }
      );
    }
  }, [user]);
}
