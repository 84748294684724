import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { siteConfig } from "../configuration/config";
import { useSettings } from "./use-settings";

export function useIntroRedirect() {
  const [redirectingToIntro, setRedirectingToIntro] = useState(false);
  const router = useRouter();
  const { settings, settingsAreLoading } = useSettings();

  useEffect(() => {
    if (siteConfig.hideIntroFlow || settingsAreLoading) {
      return;
    }

    if (
      (settings.firstVisit || Object.keys(settings).length === 0) &&
      router.pathname !== "/app/intro"
    ) {
      router.push("/app/intro");
      setRedirectingToIntro(true);
    } else if (redirectingToIntro && settings.firstVisit) {
      setRedirectingToIntro(false);
    }
  }, [
    router,
    settings,
    redirectingToIntro,
    settingsAreLoading,
    setRedirectingToIntro,
  ]);

  return {
    redirectingToIntro,
  };
}
