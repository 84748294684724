export const quizzes = [
  {
    id: "basic-grammar",
    title: "Basic Grammar Quiz",
    description: "Test your knowledge of basic grammar rules.",
    prompt:
      "I will ask you a series of questions about basic grammar rules. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question:
            "What is the correct form of the verb in this sentence: 'She ___ to the store every day'?",
          options: ["go", "goes", "going"],
          correctAnswer: "goes",
        },
        {
          question: "Which of the following is a noun?",
          options: ["run", "quickly", "happiness"],
          correctAnswer: "happiness",
        },
        {
          question: "Which sentence is correct?",
          options: [
            "He don't like apples.",
            "He doesn't like apples.",
            "He not likes apples.",
          ],
          correctAnswer: "He doesn't like apples.",
        },
        {
          question: "What is the plural form of 'child'?",
          options: ["childs", "childes", "children"],
          correctAnswer: "children",
        },
        {
          question: "Which word is an adjective?",
          options: ["running", "beautiful", "quickly"],
          correctAnswer: "beautiful",
        },
        {
          question: "What is the past tense of 'go'?",
          options: ["went", "goes", "gone"],
          correctAnswer: "went",
        },
        {
          question: "Which sentence is in the future tense?",
          options: [
            "I went to the store.",
            "I am going to the store.",
            "I will go to the store.",
          ],
          correctAnswer: "I will go to the store.",
        },
        {
          question: "Which word is a conjunction?",
          options: ["and", "quickly", "happy"],
          correctAnswer: "and",
        },
        {
          question: "Which sentence uses a preposition correctly?",
          options: [
            "She is at the park.",
            "She is on the park.",
            "She is in the park.",
          ],
          correctAnswer: "She is at the park.",
        },
        {
          question: "Which word is a pronoun?",
          options: ["they", "running", "happy"],
          correctAnswer: "they",
        },
      ],
    },
  },
];
